import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import ListElement from "./ListElement";
import Anchor from "../Anchor";

const List = ({ list, id, more }) => {
  const [index, setIndex] = useState(-1);

  useEffect(() => {}, [index]);
  return (
    <section className={styles.List} id={id}>
      <h2 className={styles.title}>{id}</h2>
      {list.map((item, i) => {
        return (
          <ListElement
            key={i}
            {...item}
            active={index !== -1 && i === index}
            inactive={index !== -1 && i !== index}
            onHover={() => setIndex(i)}
            onLeave={() => setIndex(-1)}
          />
        );
      })}
      {more && (
        <Anchor link={more.link} arrow>
          {more.text}
        </Anchor>
      )}
    </section>
  );
};

export default List;
