import { useState } from "react";
import "./App.scss";
import Landing from "./components/Landing";
import RealPortfolio from "./components/RealPortfolio";
import { Toaster } from "react-hot-toast";

function App() {
  const [open, setOpen] = useState(false);
  return (
    <div className="App">
      <Landing onClick={() => setOpen(true)} />
      <RealPortfolio open={open} />
      <Toaster />
    </div>
  );
}

export default App;
