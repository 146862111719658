import { useRef, useState } from "react";
import styles from "./index.module.scss";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const Contact = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    toast.promise(
      emailjs.sendForm("service_afqyham", "template_t90pglp", form.current, {
        publicKey: "Kx64N2_UGnrokbYxd",
      }),
      {
        loading: "Envoi en cours...",
        success: () => {
          setLoading(false);
          form.current.reset();
          return "Message envoyé !";
        },
        error: () => {
          setLoading(false);
          return "Erreur lors de l'envoi.";
        },
      }
    );
  };

  return (
    <section className={styles.contact} id="contact">
      <h2 className={styles.title}>Contact</h2>
      <p>
        Si vous avez un site web ou une application à développer, un projet à
        réaliser ou si vous avez simplement une question ou une proposition,
        n'hésitez pas à me contacter.
      </p>
      <form ref={form} onSubmit={sendEmail} className={styles.form}>
        <input
          type="text"
          placeholder="Nom"
          name="name"
          className={styles.input}
          required
        />
        <input
          type="email"
          placeholder="Email"
          name="mail"
          className={styles.input}
          required
        />
        <textarea
          placeholder="Message"
          className={styles.input}
          name="message"
          required
        />
        <button className={styles.button} disabled={loading}>
          Envoyer
        </button>
      </form>
    </section>
  );
};

export default Contact;
