//un bloc de présentation avec un titre les textes vont se faire percuter par un le bouton qui va foncer dessus
import { useState } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import AnimatedText from "../AnimatedText";
const Landing = ({ onClick }) => {
  const data = {
    name: "Eliott Depauw",
    job: "Full-stack developer",
    button: "See more",
  };
  const [started, setStarted] = useState(false);

  const randomAngleDeg = () => Math.random() * 360 + 360;

  const GenerateStyleVariableForLetter = () => {
    const dist = Math.random() * 10 + 5;
    const angle = randomAngleDeg();
    //do the math to find the position of the letter
    const x = Math.cos(angle) * dist;
    const y = Math.sin(angle) * dist;
    return {
      "--rotation": `${angle}deg`,
      "--x": `${x}em`,
      "--y": `${y}em`,
    };
  };

  return (
    <div
      onClick={() => {
        setStarted(true);
        onClick();
      }}
      className={clsx(styles.landing, started && styles.started)}
    >
      <h1 className={styles.name}>
        {new Array(data.name.length).fill(0).map((_, index) =>
          data.name[index] === " " ? (
            <div key={index} className={styles.space} />
          ) : (
            <div
              key={index}
              className={clsx(styles.letter, started && styles.started)}
              style={{
                ...GenerateStyleVariableForLetter(),
                zIndex: 10 + index,
              }}
            >
              {data.name[index]}
              <div className={styles.circle} />
            </div>
          )
        )}
      </h1>
      <AnimatedText
        text={data.job}
        Wrapper="h2"
        className={clsx(styles.job, started && styles.started)}
      />
      <p className={clsx(styles.button, started && styles.started)}>
        -- click to continue --
      </p>
    </div>
  );
};

export default Landing;
