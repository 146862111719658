import styles from "./index.module.scss";
import { ReactComponent as Arrow } from "../../assets/link.svg";
import clsx from "clsx";

const Anchor = ({
  children,
  link,
  arrow = false,
  WrapperInit = "a",
  forceHover = false,
  target = "_blank",
}) => {
  const Wrapper = link ? "a" : WrapperInit;
  const WrapperProps = link ? { href: link, target } : {};
  return (
    <Wrapper
      {...WrapperProps}
      className={clsx(styles.Anchor, forceHover && styles.forceHover)}
    >
      {children}
      {arrow && (
        <div className={styles.arrowContainer}>
          <Arrow className={styles.arrow} />
        </div>
      )}
    </Wrapper>
  );
};

export default Anchor;
