import styles from "./index.module.scss";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Github } from "../../assets/github.svg";
import { ReactComponent as Malt } from "../../assets/malt.svg";

import clsx from "clsx";

const Socials = () => {
  return (
    <div className={styles.Socials}>
      <a
        href="https://fr.linkedin.com/in/eliott-depauw-56b8b3315"
        target="_blank"
        rel="noreferrer"
        className={clsx(styles.social, styles.linkedin)}
      >
        <Linkedin />
      </a>
      <a
        href="https://github.com/edepauw"
        target="_blank"
        rel="noreferrer"
        className={clsx(styles.social, styles.github)}
      >
        <Github />
      </a>
      <a
        href="https://www.malt.fr/profile/eliottdepauw"
        target="_blank"
        rel="noreferrer"
        className={clsx(styles.social, styles.malt)}
      >
        <Malt />
      </a>
    </div>
  );
};

export default Socials;
