//un bloc de présentation avec un titre les textes vont se faire percuter par un le bouton qui va foncer dessus
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import List from "../List";
import AnimatedText from "../AnimatedText";
import TranscendenceImage from "../../assets/pong.png";
import nextCms from "../../assets/nextCms.png";
import portfolio from "../../assets/portfolio.png";
import Contact from "../Contact";
import Socials from "../Socials";
import Anchor from "../Anchor";
const RealPortfolio = ({
  open,
  ids = ["about", "experience", "projets", "contact"],
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [currentId, setCurrentId] = useState(
    window.location.hash.slice(1).split("#")[1] ?? ids[0]
  );

  const generateBackgroundGradient = () => {
    return `radial-gradient(600px at ${position.x}px ${position.y}px, rgba(29, 78, 216, 0.15), transparent)`;
  };

  useEffect(() => {
    const handler = () => {
      const scroll = window.scrollY;
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      const isEnd = scroll + windowHeight - scrollHeight === 0;

      if (isEnd) {
        setCurrentId(ids[ids.length - 1]);
        return;
      }
      const positions = ids.map((id) => {
        const element = document.getElementById(id);
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return rect.top + scroll;
      });

      positions.push(scrollHeight);
      let newId = ids[0];
      for (let i = 0; i < positions.length - 1; i++) {
        if (scroll + 1 >= positions[i] && scroll + 1 < positions[i + 1]) {
          newId = ids[i];
          break;
        }
      }
      if (newId) setCurrentId(newId);
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, [currentId, ids]);

  return (
    <div
      className={clsx(styles.realportfolio, !open && styles.blockScroll)}
      onMouseMove={(e) => {
        setPosition({ x: e.pageX, y: e.pageY });
      }}
      onScrollCapture={(e) => {
        setPosition({ x: e.pageX, y: e.pageY });
      }}
      style={{ background: generateBackgroundGradient() }}
    >
      {/* <AnimatedText text="Title H1" Wrapper="h1" /> */}
      <div className={clsx(styles.grid)}>
        <header className={styles.left}>
          <div className={styles.top}>
            <div className={styles.infos}>
              <h1>Eliott Depauw</h1>
              <h2>Développeur full-stack</h2>
              <AnimatedText
                text="Je vois le code comme un casse-tête passionnant. "
                Wrapper="p"
              />
            </div>
            <nav className={styles.nav}>
              <ul className={styles.unordered}>
                {ids.map((id) => (
                  <li
                    key={id}
                    className={clsx(currentId === id && styles.active)}
                  >
                    <a href={`#${id}`}>{id}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <Socials />
        </header>
        <div className={styles.right} id="content">
          <p className={styles.about} id="about">
            En 2015, j'ai commencé à m'intéresser à la programmation grâce aux{" "}
            <Anchor link="https://minecraft.net">jeux vidéo</Anchor>. Voyant mon
            intérêt grandir, j'ai décidé de passer les épreuves de sélection
            intensives de l'école{" "}
            <Anchor link="https://42lyon.fr">
              42 Lyon Auvergne Rhone Alpes
            </Anchor>
            , qui durent un mois, et j'ai réussi à intégrer cette prestigieuse
            institution après le lycée pour plonger plus profondément dans le
            monde de la programmation. À la fin de mon cursus, j'ai eu
            l'opportunité de faire mon stage dans une{" "}
            <Anchor link="https://smartfire.pro/">web agency</Anchor>, où j'ai
            pu travailler sur une variété de projets allant du simple site
            vitrine à des sites plus complexes avec des API. Suite à mon stage,
            j'ai continué à collaborer avec eux en freelance.
            <br />
            <br />
            Aujourd'hui, si je ne suis pas sur Visual Studio Code, je suis
            probablement sur un autre logiciel. Bien que je n'ai pas encore une
            vaste expérience, j'ai{" "}
            <Anchor link="https://google.com/">appris à apprendre</Anchor> et je
            me sens très à l'aise lorsqu'il s'agit d'aborder de nouvelles
            technologies et langages. Actuellement, je me spécialise en tant que
            développeur full-stack.
            <br />
            <br />
            Je suis capable de réaliser des sites web sur mesure, qu'ils soient
            simples ou complexes. Si vous souhaitez collaborer ou discuter d'un
            projet, n'hésitez pas à me contacter via la section{" "}
            <Anchor link="/#Contact" target="">
              contact
            </Anchor>{" "}
            de cette page.
          </p>
          <List
            id="experience"
            list={[
              {
                leftText: "2023 — 2024",
                title: "Edpw",
                link: "/#Contact",
                subtitle: "Développeur Freelance",
                target: "",
                paragraph:
                  "Mon auto-entreprise, edpw, est une entreprise de développement web spécialisée dans la création de sites web sur mesure. Je travaille avec des clients pour créer des sites web qui répondent à leurs besoins et à leurs objectifs commerciaux. Je suis capable de reproduire vos maquettes en code, de créer des sites web réactifs et accessibles, et de les optimiser pour les moteurs de recherche.",
              },
              {
                leftText: "2022 — 2023",
                title: "Smartfire",
                image:
                  "https://www.smartfire.pro/app/uploads/2021/08/profile.png",
                subtitle: "Développeur full-stack ( internship ) ",
                paragraph:
                  "Creation et maintenance de sites web. Principalement du developpement de blocs front-end en React et de plugins wordpress en PHP.",
                tags: ["Wordpress", "React", "Node.js", "PHP"],
                link: "https://www.smartfire.pro/",
                isLogo: true,
              },
              {
                leftText: "2019 — 2023",
                title: "42 Lyon Auvergne Rhone Alpes",
                image:
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXfAZMOWHDQ3DKE63A9jWhIqQaKcKqUIXvzg&s",
                subtitle: "Étudiant",
                paragraph:
                  "42 est une école d'informatique gratuite, ouverte à tous et sans condition de diplôme. Elle forme des développeurs informatiques par la pédagogie de projet et l'apprentissage par les pairs.",
                tags: [
                  "C/C++",
                  "Python",
                  "ASM",
                  "Shell",
                  "JS",
                  "React",
                  "Node.js",
                  "Docker",
                  "...",
                ],
                link: "https://42lyon.fr/",
                isLogo: true,
              },
            ]}
            more={{
              link: "/edepauw_resume.pdf",
              text: "Lien vers mon CV",
            }}
          />

          <List
            id="projets"
            list={[
              {
                title: "Ft_transcendence",
                subtitle: "Webapp ( projet 42 )",
                image: TranscendenceImage,
                paragraph:
                  "Une application web qui permet de jouer au jeu de pong en multijoueur, de discuter avec les autres joueurs et de regarder les parties en cours. Le but de ce projet est de découvrir le monde des applications web en créant un site de jeu multijoueur en temps réel grace à la technologie websockets.",
                tags: [
                  "React",
                  "Nestjs",
                  "postgresql",
                  "JWT",
                  "Websockets",
                  "Docker",
                ],
                link: "https://github.com/0xAlidy/Ft_Transcendence",
              },
              {
                title: "NextCms",
                subtitle: "Strapi X next.js",
                image: nextCms,
                link: "https://github.com/edepauw/NextCMS",
                paragraph:
                  "ReactCMS est un projet visant à simplifier l'intégration entre Strapi, un CMS headless, et React pour améliorer la collaboration avec les intégrateurs et les clients. Mes objectifs incluent la création d'une stack efficace pour les sites et webapps, la génération automatique de code pour les composants React lors de la création de nouveaux blocs dans le dashboard Strapi, et le développement de composants dynamiques essentiels pour le rendu côté serveur (SSR) et l'interface entre Next.js et Strapi.",
                tags: [
                  "Next.js",
                  "Strapi",
                  "React",
                  "SASS",
                  "Node.js",
                  "Docker",
                  "devops",
                ],
              },
              {
                title: "Portfolio",
                subtitle: "React",
                link: "/",
                image: portfolio,
                paragraph:
                  "Ce portfolio est un projet personnel qui me permet de montrer mes compétences en tant que développeur full-stack. Il est construit avec React et est entièrement personnalisable. Il est également optimisé pour les moteurs de recherche (SEO) et est accessible à tous les utilisateurs.",
                tags: ["React", "SASS"],
              },
            ]}
            more={{
              link: "https://github.com/edepauw",
              text: "Voir plus",
            }}
          />
          <Contact />

          <p>
            Inspiré par le site de{" "}
            <Anchor link="https://brittanychiang.com/">Brittany Chiang</Anchor>,
            Ce site a été developpé sur{" "}
            <Anchor link="https://code.visualstudio.com/">
              Visual Studio Code
            </Anchor>
            . Construit avec <Anchor link="https://react.dev">React</Anchor> et{" "}
            <Anchor link="https://sass-lang.com/">sass</Anchor>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RealPortfolio;
