import clsx from "clsx";
import styles from "./index.module.scss";
import Anchor from "../../Anchor";

const ListElement = ({
  leftText,
  image,
  title,
  subtitle,
  paragraph,
  tags,
  link,
  active,
  inactive,
  onHover,
  onLeave,
  target = "_blank",
  isLogo = false,
}) => {
  const Wrapper = link ? "a" : "div";
  const WrapperProps = link ? { href: link, target } : {};

  return (
    <Wrapper
      className={clsx(
        styles.ListElement,
        active && styles.active,
        inactive && styles.inactive,
        isLogo && styles.logo
      )}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      {...WrapperProps}
    >
      <div className={styles.left}>
        {leftText && <span className={styles.leftText}>{leftText}</span>}
        {image && <img src={image} alt={title} />}
      </div>
      <div className={styles.right}>
        {leftText && <span className={styles.leftTextMobile}>{leftText}</span>}
        <Anchor arrow WrapperInit="h2" forceHover={active}>
          {title}
        </Anchor>
        <h3>{subtitle}</h3>
        <p>{paragraph}</p>
        <div className={styles.tags}>
          {tags && tags.map((tag, i) => <span key={i}>{tag}</span>)}
        </div>
      </div>
    </Wrapper>
  );
};

export default ListElement;
