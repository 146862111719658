import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";

const AnimatedText = ({ text, Wrapper, className, ...props }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((prev) => prev + 1);
    }, Math.random() * 200 + 50);
    return () => clearTimeout(timeout);
  }, [index]);

  return (
    <div className={clsx(styles.AnimatedText)}>
      <Wrapper
      // {...props}
      >
        <span
          // {...props}
          className={clsx(styles.text, className, styles.hidden)}
        >
          {text}
          <span className={styles.cursor}>▋</span>
        </span>

        <span
          // {...props}
          className={clsx(styles.text, className, styles.visible)}
        >
          {text.slice(0, index)}
          <span className={styles.cursor}>▋</span>
        </span>
      </Wrapper>
    </div>
  );
};

export default AnimatedText;
